<template>
  <div class="container mt-4">
    <div class="card">
      <div
        class="card-header d-flex justify-content-between align-items-center"
      >
        <p id="cardTitle">病例清單- 共{{ numberOfElements }}筆</p>
        <button
          class="btn btn-primary"
          :disabled="disableButton"
          @click="requestDiagnoses"
        >
          Load more
        </button>
      </div>
      <div class="card-body">
        <div class="table-responsive">
          <mdb-container ref="mdbContainer">
            <mdb-datatable
              :data="data"
              striped
              bordered
              responsive
              :tfoot="false"
              previous="Previous"
              :order="[1, 'desc']"
            />
          </mdb-container>
        </div>
      </div>
    </div>
    <DiagnosisDeleteModal />
    <DiagnosisControlModal />
  </div>
</template>

<script>
import { mdbDatatable, mdbContainer } from "mdbvue";
import moment from "moment";
import DiagnosisDeleteModal from "@/components/DiagnosisDeleteModal.vue";
import DiagnosisControlModal from "@/components/DiagnosisControlModal.vue";
import { mapState } from "vuex";

export default {
  name: "DiagnosisList",
  components: {
    mdbDatatable,
    mdbContainer,
    DiagnosisDeleteModal,
    DiagnosisControlModal
  },
  data() {
    return {
      disableButton: false
    };
  },
  computed: {
    ...mapState("notes", ["notes"]),
    ...mapState({
      diagnoses: state => state.main.diagnoses,
      diagnosesUpdateTime: state => state.main.diagnosesUpdateTime,
      rows: state => state.main.rows,
      columns: state => state.main.columns,
      numberOfElements: state => state.main.numberOfElements
    }),
    data() {
      const {
        rows,
        columns,
        numberOfElements,
        diagnosesUpdateTime,
        notes,
        diagnoses
      } = this;
      return {
        rows,
        columns,
        numberOfElements,
        diagnosesUpdateTime,
        notes,
        diagnoses
      };
    }
  },
  methods: {
    filterData(diagnoses, notes) {
      let htmlData = diagnoses.map(e => {
        return {
          patient_id:
            '<a href="/diagnosis/' +
            e.diagnosis_id +
            '">' +
            e.sn_serial_no +
            "</a>",
          datetime: moment(e.create_at).format("YYYY/MM/DD , HH:mm:ss"),
          measure_type: e.measure_type,
          measure_person: e.measure_person,
          tagged:
            notes[e.diagnosis_id].length !== 0
              ? notes[e.diagnosis_id]
                  .reduce((acc, current) => acc + ", " + current)
                  .replace(/(\[|]|")*/g, "")
              : "尚未備註",
          delete:
            '<i class="deleteDiagnosisButton fa fa-trash" onclick=\'deleteDiagnosis("' +
            e.diagnosis_id +
            '", "' +
            this.$store.state.main.role +
            "\")'></i>"
        };
      });
      return htmlData;
    },
    transformNotes(notes) {
      let notes_tags = [];
      notes.forEach(e => {
        notes_tags[e.diagnosis_id] = e.note;
      });
      return notes_tags;
    },
    buildRows() {
      let new_numberOfElements = 0;
      let newUpdateTime = null;
      Promise.all([this.diagnoses, this.notes])
        .then(d => {
          let notes_tags = this.transformNotes(d[1]);
          if (d[0].length === 0) {
            throw new Error("zeroDiagnoses");
          }
          // get the oldest diagnosis date
          newUpdateTime = d[0][d[0].length - 1].create_at;
          let entries = this.filterData(d[0], notes_tags);
          new_numberOfElements = entries.length;
          this.$store.dispatch("setUpRows", {
            rows: entries,
            numberOfElements: new_numberOfElements,
            diagnosesUpdateTime: newUpdateTime
          });
          this.disableButton = false;
        })
        .catch(err => {
          if (err.message === "zeroDiagnoses") {
            console.log("No Diagnoses");
          } else {
            console.log("Initialization failed!");
          }
          this.disableButton = true;
        });
    },
    requestDiagnoses() {
      this.disableButton = true;
      this.$store
        .dispatch("requestDiagnoses")
        .then(res => {
          let newUpdateTime = null;
          Promise.resolve(this.notes)
            .then(notes => {
              var notes_tags = this.transformNotes(notes);
              // get the oldest diagnosis date
              newUpdateTime = res[res.length - 1].create_at;
              let entries = this.filterData(res, notes_tags);
              entries.map(entry => this.rows.push(entry));
              this.$store.dispatch("setUpRows", {
                rows: this.rows,
                numberOfElements: this.rows.length,
                diagnosesUpdateTime: newUpdateTime
              });
              this.disableButton = res.length !== 1000;
            })
            .catch(err => {
              alert("Data complete!");
            });
        })
        .catch(err => {
          alert("Loading failed! Try again!");
        });
    }
  },
  beforeMount() {
    this.buildRows();
  }
};
</script>

<style>
.deleteDiagnosisButton:hover {
  cursor: pointer;
}
#cardTitle {
  margin: 0;
}
</style>
