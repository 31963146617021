var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wrapper" }, [
    _c(
      "form",
      {
        staticClass: "form-signin",
        attrs: {
          action: "#",
          method: "get",
          enctype: "multipart/form-data",
          name: "signupForm",
          id: "signupForm"
        },
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.login($event)
          }
        }
      },
      [
        _c("h2", { staticClass: "form-signin-heading" }, [_vm._v("Login")]),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.username,
              expression: "username"
            }
          ],
          staticClass: "form-control",
          attrs: {
            type: "text",
            name: "username",
            placeholder: "Username",
            required: "",
            autofocus: "",
            onkeyup: "this.value=this.value.replace(/\\s+/g,'')"
          },
          domProps: { value: _vm.username },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.username = $event.target.value
            }
          }
        }),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.password,
              expression: "password"
            }
          ],
          staticClass: "form-control",
          attrs: {
            type: "password",
            name: "password",
            placeholder: "Password",
            required: ""
          },
          domProps: { value: _vm.password },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.password = $event.target.value
            }
          }
        }),
        _c(
          "button",
          {
            staticClass: "btn btn-lg btn-primary btn-block",
            attrs: { type: "submit" }
          },
          [_vm._v("\n      Login\n    ")]
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }