var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col mb-3" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-sm btn-info",
          attrs: { type: "button", onclick: "reset_zoom()" }
        },
        [_vm._v("Reset")]
      ),
      _c(
        "button",
        {
          staticClass: "btn btn-sm btn-secondary",
          attrs: { type: "button", onclick: "toggle_number(this)" }
        },
        [_vm._v("數值")]
      ),
      _c(
        "button",
        {
          staticClass: "btn btn-sm btn-info",
          attrs: { type: "button", onclick: "toggle_timeline(this)" }
        },
        [_vm._v("時間軸")]
      ),
      _c(
        "button",
        {
          staticClass: "btn btn-sm btn-secondary",
          attrs: { type: "button", onclick: "toggle_insert(this)" }
        },
        [_vm._v("標記備註")]
      ),
      _c(
        "div",
        { staticClass: "ml-2", staticStyle: { display: "inline-block" } },
        [
          _c(
            "button",
            {
              staticClass: "btn btn-sm btn-secondary",
              attrs: {
                type: "button",
                onclick: "move_page('prev')",
                title: "上一頁"
              }
            },
            [_c("i", { staticClass: "fas fa-arrow-left" })]
          ),
          _c(
            "button",
            {
              staticClass: "btn btn-sm btn-secondary",
              attrs: { type: "button", onclick: "reset_zoom()" }
            },
            [_vm._v("預設刻度")]
          ),
          _c(
            "button",
            {
              staticClass: "btn btn-sm btn-secondary",
              attrs: {
                type: "button",
                onclick: "move_page('next')",
                title: "下一頁"
              }
            },
            [_c("i", { staticClass: "fas fa-arrow-right" })]
          )
        ]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }