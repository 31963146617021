<template>
  <div class="col-12">
    <div id="chart_wrap">
      <div id="d3-chart" ref="chart"></div>
      <div id="chart_heading">
        <ul>
          <li>LEAD1</li>
          <li>LEAD2</li>
          <li>LEAD3</li>
          <li>aVR</li>
          <li>aVL</li>
          <li>aVF</li>
        </ul>
      </div>
      <div class="y_heading">(mV)</div>
      <div class="note_panel" data-x>
        <div class="content"></div>
        <div class="actions">
          <button type="button" class="btn btn-xs btn-primary" onclick="edit_note()">編輯</button>
          <button type="button" class="btn btn-xs btn-danger" onclick="delete_note()">刪除</button>
          <button type="button" class="btn btn-xs btn-light" onclick="close_note()">關閉</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as d3 from "d3";

export default {
  props: {
    raw_data: {
      required: true,
    },
    NumberOfMeasurements: {
      required: true,
    },
  },
  name: "ECGSignals",
  mounted() {
    // this.$refs.chart replaces $('#d3-chart')
    var insert_mode = false;
    var number_mode = false;
    var timeline_mode = true;
    var margin = { top: 20, right: 0, bottom: 40, left: 0 },
      margin2 = { top: 650, right: 0, bottom: 0, left: 0 },
      width = this.$refs.chart.offsetWidth - margin.left - margin.right,
      height = this.$refs.chart.offsetHeight - margin.top - margin.bottom,
      channel_height =
        (this.$refs.chart.offsetHeight - margin.top - margin.bottom) / 6,
      height2 = 10;
    var n = this.NumberOfMeasurements;
    var pin_data = {
        x1: null,
        x2: null,
        channel: null,
      },
      now_pin_num = 1,
      now_start = 0;

    console.log("d3");

    // 5. X scale will use the index of our data
    var xScale = d3
      .scaleLinear()
      .domain([0, n - 1]) // input
      .range([0, width]); // output

    // 6. Y scale will use the randomly generate number
    var yScale = d3
      .scaleLinear()
      .domain([-1.5, 1.5]) // input
      .range([channel_height, 0]); // output

    // 5. X scale will use the index of our data
    var xScale2 = d3
      .scaleLinear()
      .domain([0, n - 1]) // input
      .range([0, width]); // output

    // 6. Y scale will use the randomly generate number
    var yScale2 = d3
      .scaleLinear()
      .domain([-1.5, 1.5]) // input
      .range([50, 0]); // output

    //var xAxis = d3.axisBottom(xScale);
    var xAxis = d3.axisBottom(xScale).tickFormat("");
    var xAxis2 = d3.axisBottom(xScale2).tickFormat("");

    var yAxis = d3
      .axisLeft(yScale)
      .ticks(6)
      .tickSize(-width)
      .tickFormat("");

    var zoom = d3
      .zoom()
      .scaleExtent([1, Infinity])
      .translateExtent([
        [0, 0],
        [width, height],
      ])
      .extent([
        [0, 0],
        [width, height],
      ])
      .on("zoom", this.zoomed);

    // 7. d3's line generator
    var line = d3
      .line()
      .x(function(d, i) {
        return xScale(i);
      }) // set the x values for the line generator
      .y(function(d) {
        return yScale(d.y);
      }) // set the y values for the line generator
      .curve(d3.curveMonotoneX); // apply smoothing to the line

    var line2 = d3
      .line()
      .x(function(d, i) {
        return xScale(i);
      }) // set the x values for the line generator
      .y(function(d) {
        return yScale(d.y);
      }) // set the y values for the line generator
      .curve(d3.curveMonotoneX); // apply smoothing to the line

    var arc = d3.symbol().type(d3.symbolTriangle);

    // 8.dataset
    var context;
    // 1. Add the SVG to the page and employ #2

    var svg = d3
      .select("#d3-chart")
      .append("svg")
      .attr("class", "main_chart")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .attr("transform", "translate(" + margin.left + ",0)")
      .style("position", "absolute")
      .call(zoom);
    var clip = svg
      .append("defs")
      .append("svg:clipPath")
      .attr("id", "clip")
      .append("svg:rect")
      .attr("width", width)
      .attr("height", height + margin.top + margin.bottom)
      .attr("x", 0)
      .attr("y", margin.top);

    var xAxisMap = svg
      .append("g")
      .attr("class", "x-axis grid")
      .attr("transform", "translate(0," + margin.top + ")");
    var xAxis_line = d3
      .line()
      .x(function(d) {
        return xScale(d.x);
      })
      .y(function(d) {
        return d.y;
      });
    var xAxis_line_function = function() {
      for (var j = 0; j < this.NumberOfMeasurements; j++) {
        if (j % 25 == 0) {
          xAxisMap
            .append("path")
            .attr("class", "grid xAxis-line")
            .attr(
              "d",
              xAxis_line([
                { x: j + 1, y: 0 },
                { x: j + 1, y: height },
              ]),
            )
            .style("stroke-width", "1px");
        }
      }
    };
    xAxisMap.call(xAxis_line_function);

    //timeline
    var timeline = svg
      .append("g")
      .attr("class", "timeline")
      .attr("transform", "translate(0," + (height + margin.top + 15) + ")");
  },
  methods: {
    zoomed(svg) {
      if (d3.event.sourceEvent && d3.event.sourceEvent.type === "brush") return; // ignore zoom-by-brush

      var t = d3.event.transform;
      var mouse = d3.mouse(this);

      xScale.domain(t.rescaleX(xScale2).domain());
      svg.selectAll(".line").attr("d", line);

      $(".xAxis-line").remove();
      xAxisMap.call(xAxis_line_function);

      context
        .select(".brush")
        .call(brush.move, xScale.range().map(t.invertX, t));

      svg.selectAll(".pin-rect").attrs(pin_rect);

      $(".time").remove();
      timeline.call(timeline_function);

      if (!Number.isNaN(mouse[0])) {
        svg.selectAll(".mouse-line").attr("d", mouse_line);

        d3.select(".line_time")
          .text(Math.round((xScale.invert(mouse[0]) / 250) * 100) / 100 + "s")
          .attr(
            "transform",
            "translate(" + mouse[0] + "," + (margin.top - 5) + ")",
          );
      }
    },
  },
};
</script>

<style></style>
