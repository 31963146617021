var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container mt-4" },
    [
      _c("div", { staticClass: "card" }, [
        _c(
          "div",
          {
            staticClass:
              "card-header d-flex justify-content-between align-items-center"
          },
          [
            _c("p", { attrs: { id: "cardTitle" } }, [
              _vm._v("病例清單- 共" + _vm._s(_vm.numberOfElements) + "筆")
            ]),
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                attrs: { disabled: _vm.disableButton },
                on: { click: _vm.requestDiagnoses }
              },
              [_vm._v("\n        Load more\n      ")]
            )
          ]
        ),
        _c("div", { staticClass: "card-body" }, [
          _c(
            "div",
            { staticClass: "table-responsive" },
            [
              _c(
                "mdb-container",
                { ref: "mdbContainer" },
                [
                  _c("mdb-datatable", {
                    attrs: {
                      data: _vm.data,
                      striped: "",
                      bordered: "",
                      responsive: "",
                      tfoot: false,
                      previous: "Previous",
                      order: [1, "desc"]
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ])
      ]),
      _c("DiagnosisDeleteModal"),
      _c("DiagnosisControlModal")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }