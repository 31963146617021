<template>
  <div
    id="page-modal-diagnosis-control"
    class="modal fade show"
    data-mode=""
    tabindex="-1"
    role="dialog"
    aria-labelledby="ModalLabel"
    aria-modal="true"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <form id="modal_form">
          <div class="modal-header">
            <h5 class="modal-title">No permission</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            You are not allowed to delete a diagnosis.
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-success" data-dismiss="modal">
              OK
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DiagnosisControlModal"
};
</script>

<style></style>
