var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c(
        "div",
        {
          staticClass:
            "navbar navbar-dark bg-primary shadow-sm navbar-expand-md",
          attrs: { id: "main_nav" }
        },
        [
          _c("a", { staticClass: "navbar-brand", attrs: { href: "/" } }, [
            _vm._v("inCare")
          ]),
          _vm._m(0),
          _c(
            "div",
            {
              staticClass: "collapse navbar-collapse",
              attrs: { id: "navbarText" }
            },
            [
              _vm._m(1),
              _c("nav", { staticClass: "navbar-text" }, [
                _vm.loggedIn
                  ? _c("a", { attrs: { href: "#" } }, [
                      _vm._v("身份：" + _vm._s(_vm.userName))
                    ])
                  : _vm._e(),
                _vm.loggedIn ? _c("a", [_vm._v("  |  ")]) : _vm._e(),
                _vm.isAdmin
                  ? _c(
                      "a",
                      { attrs: { href: "#" } },
                      [
                        _c(
                          "router-link",
                          { attrs: { to: { name: "register" } } },
                          [_vm._v("Create User")]
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.isAdmin ? _c("a", [_vm._v("  |  ")]) : _vm._e(),
                _vm.isAdmin
                  ? _c(
                      "a",
                      { attrs: { href: "#" } },
                      [
                        _c(
                          "router-link",
                          { attrs: { to: { name: "generate_token" } } },
                          [_vm._v("Generate API Token")]
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.isAdmin ? _c("a", [_vm._v("  |  ")]) : _vm._e(),
                _vm.loggedIn
                  ? _c(
                      "a",
                      { attrs: { href: "#" }, on: { click: _vm.logout } },
                      [_vm._v("登出")]
                    )
                  : _vm._e(),
                !_vm.loggedIn
                  ? _c(
                      "a",
                      [
                        _c(
                          "router-link",
                          { attrs: { to: { name: "login" } } },
                          [_vm._v("Login")]
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                !_vm.loggedIn ? _c("a", [_vm._v("  ")]) : _vm._e()
              ])
            ]
          )
        ]
      ),
      _c("router-view", { on: { displayUsername: _vm.updateUsername } }),
      _vm._m(2)
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass: "navbar-toggler",
        attrs: {
          type: "button",
          "data-toggle": "collapse",
          "data-target": "#navbarText",
          "aria-controls": "navbarText",
          "aria-expanded": "false",
          "aria-label": "Toggle navigation"
        }
      },
      [_c("span", { staticClass: "navbar-toggler-icon" })]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", { staticClass: "navbar-nav mr-auto" }, [
      _c("li", { staticClass: "nav-item" }, [
        _c(
          "a",
          {
            staticClass: "nav-link",
            attrs: { href: "/diagnosis", id: "link_dlist" }
          },
          [_vm._v("Diagnoses")]
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("footer", { staticClass: "copyright text-center" }, [
      _c("small", { staticClass: "d-block mb-3 text-muted" }, [
        _vm._v("inCare © 2019")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }