import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store/store";
import "./registerServiceWorker";
import "bootstrap/dist/css/bootstrap.min.css";
import "../public/css/style.css";
import "../public/css/fontawesome-all.min.css";
import "../public/css/dataTables.bootstrap4.min.css";
import "nprogress/nprogress.css";

Vue.config.productionTip = false;

const importer = {
  url: url => {
    return new Promise((resolve, reject) => {
      let script = document.createElement("script");
      script.type = "text/javascript";
      script.src = url;
      script.async = "async";
      script.addEventListener("load", () => resolve(script), false);
      script.addEventListener("error", () => reject(script), false);
      document.body.appendChild(script);
    });
  },
  urls: urls => {
    return Promise.all(urls.map(importer.url));
  }
};

importer
  .urls([
    "../js/jquery-3.3.1.min.js",
    "https://d3js.org/d3-selection-multi.v1.min.js",
    "https://d3js.org/d3-array.v2.min.js",
    "../js/d3-symbol-extra.min.js",
    "../js/d3_display.js",
    "../js/bootstrap.bundle.min.js",
    "https://unpkg.com/axios/dist/axios.min.js",
    "../js/deleteDialog.js"
  ])
  .then(() => console.log("All scripts loaded"));

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
