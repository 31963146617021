var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "col-12" }, [
      _c("ul", { staticClass: "detail_item_list" }, [
        _c("li", [
          _c("label", [_vm._v("類型")]),
          _c("span", { staticClass: "diagnosis_type" }, [
            _vm._v(_vm._s(_vm.diagnosis_type))
          ])
        ]),
        _c("li", [
          _c("label", [_vm._v("測量倍數")]),
          _c("span", { staticClass: "measure_times" }, [
            _vm._v(_vm._s(_vm.measure_times))
          ])
        ]),
        _c("li", [
          _c("label", [_vm._v("測量狀態")]),
          _c("span", { staticClass: "measure_type" }, [
            _vm._v(_vm._s(_vm.measure_type))
          ])
        ]),
        _c("li", [
          _c("label", [_vm._v("測量人員")]),
          _c("span", { staticClass: "measure_person" }, [
            _vm._v(_vm._s(_vm.measure_person))
          ])
        ])
      ])
    ]),
    _c("div", { staticClass: "col-12" }, [
      _c("ul", { staticClass: "detail_item_list" }, [
        _c("li", [
          _c("label", [_vm._v("年齡")]),
          _c("span", { staticClass: "age" }, [_vm._v(" " + _vm._s(_vm.age))])
        ]),
        _c("li", [
          _c("label", [_vm._v("性別")]),
          _c("span", { staticClass: "gender" }, [_vm._v(_vm._s(_vm.gender))])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }