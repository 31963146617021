import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";
import * as main from "@/store/modules/tempMain.js";
import * as notes from "@/store/modules/notes.js";

Vue.use(Vuex);

// TODO replace temporary main module with more and more modules
const vuexMain = new VuexPersistence({
  key: "vuexMain",
  storage: window.localStorage,
  reducer: state => ({ main: state.main })
});
const vuexNotes = new VuexPersistence({
  key: "vuexNotes",
  storage: window.localStorage,
  reducer: state => ({ notes: state.notes })
});

export default new Vuex.Store({
  modules: {
    main,
    notes
  },
  plugins: [vuexMain.plugin, vuexNotes.plugin]
});
