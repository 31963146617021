<template>
    <div class="col mb-3">
        <button type="button" class="btn btn-sm btn-info" onclick="reset_zoom()">Reset</button>
        <button type="button" class="btn btn-sm btn-secondary" onclick="toggle_number(this)">數值</button>
        <button type="button" class="btn btn-sm btn-info" onclick="toggle_timeline(this)">時間軸</button>
        <button type="button" class="btn btn-sm btn-secondary" onclick="toggle_insert(this)">標記備註</button>
        <div class="ml-2" style="display:inline-block">
            <button type="button" class="btn btn-sm btn-secondary" onclick="move_page('prev')" title="上一頁"><i class="fas fa-arrow-left"></i></button>
            <button type="button" class="btn btn-sm btn-secondary" onclick="reset_zoom()">預設刻度</button>
            <button type="button" class="btn btn-sm btn-secondary" onclick="move_page('next')" title="下一頁"><i class="fas fa-arrow-right"></i></button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ButtonsGroup'
}
</script>

<style>

</style>
