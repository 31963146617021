<template>
  <div class="home">
    <div class="home_image">
      <img :src="home_image" />
    </div>
    <h1>{{ greeting }}</h1>
  </div>
</template>

<script>
export default {
  name: "home",
  data() {
    return {
      greeting: "Welcome to inCare App",
      home_image: require("../assets/home_brand.png")
    };
  }
};
</script>

<style scoped>
.home {
  text-align: center;
  height: 120px;
}
.home_image {
  margin-top: 64px;
  margin-bottom: 32px;
}
</style>
