<template>
    <div>
        <div class="col-12">
                        <ul class="detail_item_list">
                            <li><label>類型</label><span class='diagnosis_type'>{{ diagnosis_type }}</span></li>
                            <li><label>測量倍數</label><span class='measure_times'>{{ measure_times }}</span></li>
                            <li><label>測量狀態</label><span class='measure_type'>{{ measure_type }}</span></li>
                            <li><label>測量人員</label><span class='measure_person'>{{ measure_person }}</span></li>
                        </ul>
                    </div>
                    <div class="col-12">
                        <ul class="detail_item_list">
                            <!-- <li><label>病患</label></li> -->
                            <li><label>年齡</label><span class='age'> {{ age }}</span></li>
                            <li><label>性別</label><span class='gender'>{{ gender }}</span></li>
                        </ul>
                    </div>
    </div>
</template>

<script>
export default {
    props: {
        age:{
            required: true,
            default: '-'
        },
        gender:{
            type: String,
            required: true,
            default: '-'
        },
        diagnosis_type:{
            type: String,
            required: true,
            default: '-'
        },
        measure_times:{
            type: String,
            required: true,
            default: '-'
        },
        measure_type:{
            type: String,
            required: true,
            default: '-'
        },
        measure_person:{
            type: String,
            required: true,
            default: '-'
        }
    },
    name: "DiagnosisInfo"
}
</script>

<style>

</style>
