export default {
  SAVE_USERNAME(state, username) {
    state.username = username;
  },
  DELETE_USERNAME(state) {
    state.username = null;
  },
  SAVE_TOKEN(state, token) {
    state.token = token;
  },
  DELETE_TOKEN(state) {
    state.token = null;
  },
  SAVE_ROLE(state, role) {
    state.role = role;
  },
  DELETE_ROLE(state) {
    state.role = null;
  },
  SAVE_ROWS(state, rows) {
    state.rows = rows;
  },
  DELETE_ROWS(state) {
    state.rows = null;
  },
  SAVE_ROWS_NUMBER(state, number) {
    state.numberOfElements = number;
  },
  DELETE_ROWS_NUMBER(state) {
    state.numberOfElements = 0;
  },
  SAVE_UPDATE_TIME(state, date) {
    state.diagnosesUpdateTime = date;
  },
  DELETE_UPDATE_TIME(state) {
    state.diagnosesUpdateTime = null;
  },
  SAVE_DIAGNOSIS(state, diagnoses) {
    state.diagnoses = diagnoses;
  },
  DELETE_DIAGNOSIS(state) {
    state.diagnoses = [];
  },
  INSERT_DIAGNOSIS(state, moreDiagnoses) {
    state.diagnoses = state.diagnoses.concat(moreDiagnoses);
  },
  DELETE_DIAGNOSIS_BY_ID(state, diagnosis_id) {
    for (var i = 0; i < state.diagnoses.length; i++) {
      if (state.diagnoses[i].diagnosis_id == diagnosis_id.toString()) {
        state.diagnoses.splice(i, 1);
        break;
      }
    }
  },
  SAVE_ANOMALY_MODE(state) {
    state.anomalyMode = true;
  },
  DELETE_ANOMALY_MODE(state) {
    state.anomalyMode = false;
  },
  SAVE_ANOMALY_SEQUENCE(state, sequence) {
    state.anomalySequence = sequence;
  },
  DELETE_ANOMALY_SEQUENCE(state) {
    state.anomalySequence = [];
  },
  SAVE_EVALUATION(state, evaluation) {
    state.evaluationTags = evaluation;
  },
  DELETE_EVALUATION(state) {
    state.evaluationTags = [];
  },
  SAVE_MODELS(state, models) {
    state.anomalyModels = models;
  }
};
