<template>
      <div class="wrapper">
        <form action="#" @submit.prevent='register' method="get" enctype="multipart/form-data" name="signupForm" class="form-signin" id="signupForm">  
            <h2 class="form-signin-heading">Generate Token</h2>
            <input type="text" class="form-control" placeholder="API User Username" required="" autofocus=""  v-model="api_user_username" onkeyup="this.value=this.value.replace(/\s+/g,'')"/>
            <br />
            <button class="btn btn-lg btn-primary btn-block" type="submit">Generate</button>   

             <br />
            <input type="text" class="form-control" name="token" placeholder="Token will appear here" v-model="token"/>
        </form>
    </div>
</template>

<script>
export default {
    data(){
        return {
            api_user_username: '',
            token: ''
        }
    },
    methods: {
        register(){
            this.$store.dispatch('generateToken', {
                api_user_username: this.api_user_username,
            }).then(resp => {
              if(resp.access_token == ''){
                alert('This user does not exist. Please type in the correct username');
              }else{
                  this.token = resp.access_token;
                //this.token = 'dakjhdkjsahdasd';
              }
              
            });
        }
    }
}
</script>

<style>
     .form-signin {
  max-width: 380px;
  padding: 15px 35px 45px;
  margin: 0 auto;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.form-signin .form-signin-heading,
.form-signin .checkbox {
  margin-bottom: 30px;
}
.form-signin .checkbox {
  font-weight: normal;
}
.form-signin .form-control {
  position: relative;
  font-size: 16px;
  height: auto;
  padding: 10px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.form-signin .form-control:focus {
  z-index: 2;
}
select{
    margin-bottom: 40px;
}
.form-signin input[type="text"] {
  margin-bottom: -1px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.form-signin input[type="password"] {
  margin-bottom: 20px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
</style>
