<template>
  <div>
    <div class="container mt-5">
      <div class="card">
        <div class="card-header">
          病例
          <span class="text-primary sn_serial_no"></span> 詳細資料
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <ul class="detail_item_list">
                <li>
                  <label>類型</label>
                  <span class="diagnosis_type"></span>
                </li>
                <li>
                  <label>測量倍數</label>
                  <span class="measure_times"></span>
                </li>
                <li>
                  <label>測量狀態</label>
                  <span class="measure_type"></span>
                </li>
                <li>
                  <label>測量人員</label>
                  <span class="measure_person"></span>
                </li>
              </ul>
            </div>
            <div class="col-12">
              <ul class="detail_item_list">
                <li>
                  <label>年齡</label>
                  <span class="age"></span>
                </li>
                <li>
                  <label>性別</label>
                  <span class="gender"></span>
                </li>
              </ul>
            </div>
          </div>
          <div class="row">
            <!-- <ButtonsGroup /> -->
            <div class="col mb-3">
              <!-- <button type="button" class="btn btn-sm btn-info" onclick="reset_zoom()">Reset</button> -->
              <router-link :to="{ name: 'diagnosis' }">
                <button type="button" class="btn btn-sm btn-secondary" title="Dashboard">
                  <i class="fa fa-home"></i>
                </button>
              </router-link>
              <button
                type="button"
                class="btn btn-sm btn-secondary"
                onclick="toggle_number(this)"
              >數值</button>
              <button type="button" class="btn btn-sm btn-info" onclick="toggle_timeline(this)">時間軸</button>
              <button
                type="button"
                class="btn btn-sm btn-secondary m-0"
                onclick="toggle_insert(this)"
              >標記備註</button>
              <div class="form-group col-md-5 d-inline">
                <button
                  :disabled="!anomalyMode"
                  type="button"
                  class="btn btn-sm btn-secondary"
                  @click="changeMode()"
                >Change Model / Update</button>
                <select id="inputState" class="form-control-sm" v-model="selectedModel">
                  <option disabled value>Please select model for evaluation</option>
                  <option v-for="item in models" :key="item.id">{{ item }}</option>
                </select>
              </div>
              <div class="mt-2">
                <button
                  type="button"
                  class="btn btn-sm btn-secondary"
                  onclick="move_page('prev')"
                  title="上一頁"
                >
                  <i class="fas fa-arrow-left"></i>
                </button>
                <button type="button" class="btn btn-sm btn-secondary" onclick="reset_zoom()">預設刻度</button>
                <button
                  type="button"
                  class="btn btn-sm btn-secondary"
                  onclick="move_page('next')"
                  title="下一頁"
                >
                  <i class="fas fa-arrow-right"></i>
                </button>
                <button
                  :disabled="anomalyMode"
                  type="button"
                  class="btn btn-sm btn-secondary"
                  @click="getAnomaly"
                >Evaluation Mode</button>
                <button
                  :disabled="!anomalyMode"
                  type="button"
                  class="btn btn-sm btn-secondary"
                  @click="toggleAnomaly()"
                >Tag Mode</button>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div id="chart_wrap">
                <div id="d3-chart" ref="chart"></div>
                <div id="chart_heading">
                  <ul>
                    <li>LEAD1</li>
                    <li>LEAD2</li>
                    <li>LEAD3</li>
                    <li>aVR</li>
                    <li>aVL</li>
                    <li>aVF</li>
                  </ul>
                </div>
                <div class="y_heading">(mV)</div>
                <div class="note_panel" data-x>
                  <div class="content"></div>
                  <div class="actions">
                    <button type="button" class="btn btn-xs btn-primary" onclick="edit_note()">編輯</button>
                    <button
                      :disabled="anomalyMode"
                      type="button"
                      class="btn btn-xs btn-danger"
                      onclick="delete_note()"
                    >刪除</button>
                    <button type="button" class="btn btn-xs btn-light" onclick="close_note()">關閉</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <NoteModal />
    <NoteDeleteModal />
  </div>
</template>

<script>
import ButtonsGroup from "@/components/ButtonsGroup.vue";
import ECGSignals from "@/components/ECGSignals.vue";
import DiagnosisInfo from "@/components/DiagnosisInfo.vue";
import NoteModal from "@/components/NoteModal.vue";
import NoteDeleteModal from "@/components/NoteDeleteModal.vue";
import * as apiUrl from "../api_url.global";
import { mapActions, mapState } from "vuex";

export default {
  name: "diagnosis_details",
  components: {
    ButtonsGroup,
    ECGSignals,
    DiagnosisInfo,
    NoteModal,
    NoteDeleteModal
  },
  data() {
    return {
      NumberOfMeasurements: 0,
      loadedData: [],
      sn_serial_no: "",
      diagnosis_type: "",
      measure_times: "",
      measure_type: "",
      measure_person: "",
      age: "",
      gender: "",
      selectedModel: ""
    };
  },
  beforeMount() {
    this.getAnomalyModels();
  },
  mounted() {
    localStorage.setItem("url", apiUrl.url);
    localStorage.setItem("token", apiUrl.token);
  },
  computed: {
    ...mapState({
      anomalyMode: state => state.main.anomalyMode,
      models: state => state.main.anomalyModels
    }),
    data() {
      return {
        anomalyMode: this.anomalyMode,
        models: this.models
      };
    }
  },
  methods: {
    ...mapActions([
      "handleAnomalyState",
      "toggleAnomaly",
      "changeMode",
      "getAnomalyModels"
    ]),
    getAnomaly() {
      localStorage.setItem("model_name", this.selectedModel);
      if (this.selectedModel) {
        //console.log(this.selectedModel);
        this.handleAnomalyState({
          diagnosisId: this.$route.params.diagnosis_id,
          model_name: this.selectedModel
        });
      } else {
        alert("Select Model Please");
      }
    }
  }
};
</script>

<style>
.btn {
  margin-right: 10px;
}
</style>
