import tempActions from "./tempActions.js";
import tempMutations from "./tempMutations.js";
import tempGetters from "./tempGetters.js";
import tempState from "./tempState.js";

export const state = tempState;

export const mutations = tempMutations;

export const actions = tempActions;

export const getters = tempGetters;
