module.exports = {
  getAnomalyPredictionNumber(diagnosis) {
    switch (diagnosis) {
      case "Normal Sinus Rhythm":
        return 0;
      case "APC":
        return 1;
      case "ST-D":
        return 2;
      case "ST-E":
        return 3;
      case "VPC":
        return 4;
      case "Atrial Fibrillation":
        return 5;
      case "PSVT":
        return 6;
      case "ST-N":
        return 7;
      case "Unknown":
        return 8;
      case "BBB":
        return 9;
      case "OMI":
        return 10;
    }
  }
};
