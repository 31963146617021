<template>
  <div
    id="page-modal-confirm"
    class="modal fade show"
    data-mode
    tabindex="-1"
    role="dialog"
    aria-labelledby="ModalLabel"
    aria-modal="true"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <form id="modal_form" @submit.prevent="deleteNote">
          <div class="modal-header">
            <h5 class="modal-title">Please confirm</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">Do you really want to delete this tag?</div>
          <div class="modal-footer">
            <button type="submit" class="btn btn-success">Yes</button>
            <button type="button" class="btn btn-secondary" data-dismiss="modal">No</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NoteDeleteModal",

  methods: {
    deleteNote() {
      $("#page-modal-confirm").modal("hide");

      this.$store
        .dispatch("notes/deleteNote", {
          note_id: localStorage.getItem("note_id_to_delete")
        })
        .then(note_id => {
          removing_pin(note_id);
        });
    }
  }
};
</script>

<style></style>
