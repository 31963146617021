import * as apiUrl from "../../api_url.global";
import axios from "axios";

export const namespaced = true;

export const state = {
  notes: null
};

export const mutations = {
  SAVE_NOTES(state, notes) {
    state.notes = notes;
  },
  DELETE_NOTES(state) {
    state.notes = [];
  },
  INSERT_NOTES(state, note) {
    state.notes.push(note);
  },
  INSERT_NOTES_BATCH(state, moreNotes) {
    state.notes = state.notes.concat(moreNotes);
  },
  DELETE_NOTE_BY_ID(state, note_id) {
    for (var i = 0; i < state.notes.length; i++) {
      if (state.notes[i].id == note_id) {
        state.notes.splice(i, 1);
        break;
      }
    }
  },
  UPDATE_NOTE(state, note) {
    for (var i = 0; i < state.notes.length; i++) {
      if (state.notes[i].id == note.id) {
        state.notes[i].note = note.note;
        break;
      }
    }
  }
};

export const actions = {
  saveNotes({ commit }, notes) {
    commit("SAVE_NOTES", notes);
  },
  deleteNotes({ commit }) {
    commit("DELETE_NOTES");
  },
  addNotes({ commit }, notes) {
    commit("INSERT_NOTES_BATCH", notes);
  },
  addNote({ commit, dispatch, rootState }, data) {
    const note = {
      id: "",
      diagnosis_id: data.diagnosis_id.toString(),
      x1: data.x1.toString(),
      x2: data.x2.toString(),
      channel: data.channel.toString(),
      note: '["' + data.note.toString() + '"]'
    };
    const config = {
      url: apiUrl.url + "/medical/v1/notes/create",
      headers: {
        Authorization: "Bearer " + rootState.token,
        "Content-Type": "application/json"
      },
      method: "post",
      data: note
    };
    return axios(config)
      .then(res => {
        const resData = res.data.data.datas[0];
        note.id = resData.note_id;
        note["created_at"] = resData.created_at;
        commit("INSERT_NOTES", note);
        dispatch("main/deleteAnomalyMode", { root: true });
        return resData.note_id;
      })
      .catch(err => console.log(err));
  },
  deleteNote({ commit, rootState }, data) {
    const config = {
      url: apiUrl.url + "/medical/v1/notes/delete/" + data.note_id,
      headers: {
        Authorization: "Bearer " + rootState.token,
        "Content-Type": "application/json"
      },
      method: "delete"
    };
    return new Promise((resolve, reject) => {
      axios(config)
        .then(() => {
          commit("DELETE_NOTE_BY_ID", data.note_id.toString());
          resolve(data.note_id);
        })
        .catch(err => console.log(err));
    });
  },
  editNote({ commit, rootState }, data) {
    const note = {
      id: data.id.toString(),
      diagnosis_id: "",
      x1: data.x1.toString(),
      x2: data.x2.toString(),
      channel: data.channel.toString(),
      note: '["' + data.note.toString() + '"]'
    };
    const config = {
      url: apiUrl.url + "/medical/v1/notes/modify",
      headers: {
        Authorization: "Bearer " + rootState.token,
        "Content-Type": "application/json"
      },
      method: "put",
      data: note
    };
    axios(config)
      .then(() => {
        commit("UPDATE_NOTE", note);
      })
      .catch(err => console.log(err));
  }
};
