<template>
  <div class="wrapper">
    <form
      action="#"
      @submit.prevent="login"
      method="get"
      enctype="multipart/form-data"
      name="signupForm"
      class="form-signin"
      id="signupForm"
    >
      <h2 class="form-signin-heading">Login</h2>
      <input
        type="text"
        class="form-control"
        name="username"
        placeholder="Username"
        required
        autofocus
        v-model="username"
        onkeyup="this.value=this.value.replace(/\s+/g,'')"
      />
      <input
        type="password"
        class="form-control"
        name="password"
        placeholder="Password"
        required
        v-model="password"
      />
      <button class="btn btn-lg btn-primary btn-block" type="submit">
        Login
      </button>
    </form>
  </div>
</template>

<script>
import { setTimeout } from "timers";
export default {
  name: "login",
  data() {
    return {
      username: "",
      password: ""
    };
  },
  methods: {
    login() {
      this.$store
        .dispatch("retrieveToken", {
          username: this.username,
          password: this.password
        })
        .then(() => {
          this.$emit("displayUsername", this.username);
          this.$router.push({ name: "diagnosis" });
        })
        .catch(err => {
          this.username = "";
          this.password = "";
          alert("Wrong username or password");
        });
    }
  }
};
</script>

<style scoped>
.form-signin {
  max-width: 380px;
  padding: 15px 35px 45px;
  margin: 0 auto;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.form-signin .form-signin-heading,
.form-signin .checkbox {
  margin-bottom: 30px;
}
.form-signin .checkbox {
  font-weight: normal;
}
.form-signin .form-control {
  position: relative;
  font-size: 16px;
  height: auto;
  padding: 10px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.form-signin .form-control:focus {
  z-index: 2;
}
.form-signin input[type="text"] {
  margin-bottom: -1px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.form-signin input[type="password"] {
  margin-bottom: 20px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
</style>
