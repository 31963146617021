<template>
  <div
    id="page-modal-diagnosis-confirm"
    class="modal fade show"
    data-mode
    tabindex="-1"
    role="dialog"
    aria-labelledby="ModalLabel"
    aria-modal="true"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <form id="modal_form" @submit.prevent="deleteDiagnosis">
          <div class="modal-header">
            <h5 class="modal-title">Please confirm</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            Do you really want to delete this diagnosis?
          </div>
          <div class="modal-footer">
            <button type="submit" class="btn btn-success">Yes</button>
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              No
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import * as apiUrl from "../api_url.global";
import { mapState } from "vuex";

export default {
  name: "DiagnosisDeleteModal",
  computed: {
    ...mapState("notes", ["notes"]),
    ...mapState({
      diagnoses: state => state.main.diagnoses,
      diagnosesUpdateTime: state => state.main.diagnosesUpdateTime
    }),
    data() {
      const { notes, diagnoses, diagnosesUpdateTime } = this;
      return {
        notes,
        diagnoses,
        diagnosesUpdateTime
      };
    }
  },
  methods: {
    deleteDiagnosis() {
      $("#page-modal-diagnosis-confirm").modal("hide");

      this.$store
        .dispatch("deleteDiagnosis", {
          diagnosis_id: localStorage.getItem("diagnosis_id_to_delete")
        })
        .then(diagnosis_id => {
          let new_rows = [];
          let new_numberOfElements = 0;
          Promise.all([
            this.diagnoses,
            this.notes,
            this.diagnosesUpdateTime
          ]).then(d => {
            var notes_tags = this.transformNotes(d[1]);
            let entries = this.filterData(d[0], notes_tags);
            entries.map(entry => new_rows.push(entry));
            new_numberOfElements = entries.length;
            this.$store.dispatch("setUpRows", {
              rows: new_rows,
              numberOfElements: new_numberOfElements,
              diagnosesUpdateTime: d[2]
            });
          });
          console.log(diagnosis_id, new_numberOfElements, new_rows);
        });
    },
    filterData(diagnoses, notes) {
      let htmlData = diagnoses.map(e => {
        return {
          patient_id:
            '<a href="/diagnosis/' +
            e.diagnosis_id +
            '">' +
            e.sn_serial_no +
            "</a>",
          datetime: moment(e.create_at).format("YYYY/MM/DD , HH:mm:ss"),
          measure_type: e.measure_type,
          measure_person: e.measure_person,
          tagged:
            notes[e.diagnosis_id].length !== 0
              ? notes[e.diagnosis_id]
                  .reduce((acc, current) => acc + ", " + current)
                  .replace(/(\[|]|")*/g, "")
              : "尚未備註",
          delete:
            '<i class="deleteDiagnosisButton fa fa-trash" onclick=\'deleteDiagnosis("' +
            e.diagnosis_id +
            "\")'></i>"
        };
      });
      return htmlData;
    },
    transformNotes(notes) {
      var notes_tags = [];
      notes.forEach(e => {
        notes_tags[e.diagnosis_id] = e.note;
      });
      return notes_tags;
    }
  }
};
</script>

<style></style>
