<template>
  <div class="wrapper">
    <form
      action="#"
      @submit.prevent="register"
      method="get"
      enctype="multipart/form-data"
      name="signupForm"
      class="form-signin"
      id="signupForm"
    >
      <h2 class="form-signin-heading">Register</h2>
      <input
        type="text"
        class="form-control"
        name="username"
        placeholder="Username"
        required
        autofocus
        v-model="username"
        onkeyup="this.value=this.value.replace(/\s+/g,'')"
      />
      <input
        type="password"
        class="form-control"
        name="password"
        placeholder="Password"
        required
        v-model="password"
      />
      <label for="user-role">Role</label>
      <select class="form-control" v-model="role" id="role">
        <option value="hospital">Hospital</option>
        <option value="api" selected="selected">API User</option>
        <option value="admin">Admin</option>
      </select>
      <label for="hospital-access">Hospital Access</label>
      <select class="form-control" v-model="hospitalAccess" id="hospital">
        <option value="yuan">yuan</option>
        <!-- <option value="hospital">Hospital</option> -->
      </select>
      <button class="btn btn-lg btn-primary btn-block" type="submit">Register</button>
    </form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      username: "",
      password: "",
      role: "",
      hospitalAccess: "",
    };
  },
  methods: {
    register() {
      this.$store
        .dispatch("registerUser", {
          username: this.username,
          password: this.password,
          role: this.role,
          hospitalAccess: this.hospitalAccess,
        })
        .then((resp) => {
          alert("You successfully created " + this.username);
          this.username = "";
          this.password = "";
          this.role = "";
          this.hospitalAccess = "";
        });
    },
  },
};
</script>

<style>
.form-signin {
  max-width: 380px;
  padding: 15px 35px 45px;
  margin: 0 auto;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.form-signin .form-signin-heading,
.form-signin .checkbox {
  margin-bottom: 24px !important;
}
.form-signin .checkbox {
  font-weight: normal;
}
.form-signin .form-control {
  position: relative;
  font-size: 16px;
  height: auto;
  padding: 10px;
  margin-bottom: 12px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.form-signin .form-control:focus {
  z-index: 2;
}
select {
  margin-bottom: 40px;
}
.form-signin input[type="text"] {
  margin-bottom: -1px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.form-signin input[type="password"] {
  margin-bottom: 12px !important;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.form-control[id="hospital"] {
  margin-bottom: 24px;
}
</style>
