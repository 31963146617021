<template>
  <div
    id="page-modal"
    class="modal fade show"
    data-mode
    tabindex="-1"
    role="dialog"
    aria-labelledby="ModalLabel"
    aria-modal="true"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <form id="modal_form" @submit.prevent="addNotes">
          <input type="hidden" id="note_id" value v-model="id" />
          <input type="hidden" id="note_channel" value v-model="channel" />
          <input type="hidden" id="note_x1" value v-model="x1" />
          <input type="hidden" id="note_x2" value v-model="x2" />
          <input type="hidden" id="note_target" value v-model="target" />

          <div class="modal-header">
            <h5 class="modal-title">新增備註</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row pl-3">
              <div style="width: 50px">
                <label for="note">備註</label>
              </div>
              <div style="width: 100px">
                <div class="form-check">
                  <label>
                    <input type="radio" value="OMI" v-model="note" />
                    OMI
                  </label>
                  <label>
                    <input type="radio" value="ST-E" v-model="note" />
                    ST-E
                  </label>
                  <label>
                    <input type="radio" value="ST-D" v-model="note" />
                    ST-D
                  </label>
                </div>
              </div>
              <div style="width: 300px">
                <div class="form-check">
                  <label>
                    <input
                      type="radio"
                      value="Normal Sinus Rhythm"
                      v-model="note"
                    />
                    Normal Sinus Rhythm
                  </label>
                  <br />
                  <label>
                    <input
                      type="radio"
                      value="Atrial Fibrillation"
                      v-model="note"
                    />
                    Atrial Fibrillation
                  </label>
                  <br />
                  <label>
                    <input type="radio" value="PSVT" v-model="note" />
                    PSVT
                  </label>
                  <br />
                  <label>
                    <input type="radio" value="APC" v-model="note" />
                    APC
                  </label>
                  <br />
                  <label>
                    <input type="radio" value="VPC" v-model="note" />
                    VPC
                  </label>
                  <br />
                  <label>
                    <input type="radio" value="BBB" v-model="note" />
                    BBB
                  </label>
                </div>
                <div class="form-check">
                  <label>
                    <input type="radio" value="Unknown" v-model="note" />
                    Unknown
                  </label>
                  <br />
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="submit" class="btn btn-success">確定</button>
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              取消
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "NoteModal",
  data() {
    return {
      id: null,
      x1: null,
      x2: null,
      target: null,
      channel: null,
      note: "Unknown",
      mode: null
    };
  },
  computed: {
    ...mapState({
      anomalyMode: state => state.main.anomalyMode
    }),
    data() {
      return {
        anomalyMode: this.anomalyMode
      };
    }
  },
  methods: {
    addNotes() {
      let x1 = localStorage.getItem("note_x1");
      let x2 = localStorage.getItem("note_x2");
      let channel = localStorage.getItem("note_channel");
      let diagnosisId = localStorage.getItem("note_diagnosis_id");
      let noteMode = localStorage.getItem("note_mode");
      let model_name = localStorage.getItem("model_name");
      let ai_answer = localStorage.getItem("ai_answer");
      let localStorageAnomalySequence = localStorage.getItem(
        "localStorageAnomalySequence"
      );
      $("#page-modal").modal("hide");
      if (noteMode === "add") {
        if (this.anomalyMode) {
          this.evaluatePrediction({
            diagnosisId: diagnosisId,
            note: this.note,
            x1: x1,
            x2: x2,
            channel: channel,
            model_name: model_name,
            ai_answer: "Normal Sinus Rhythm",
            localStorageAnomalySequence: localStorageAnomalySequence
          });
        } else {
          this.addNote({
            diagnosis_id: diagnosisId,
            x1: x1,
            x2: x2,
            target: this.target,
            channel: channel,
            note: this.note,
            mode: noteMode
          }).then(note_id => {
            add_pin(note_id, this.note);
          });
        }
      }
      if (noteMode === "edit") {
        if (this.anomalyMode) {
          this.evaluatePrediction({
            diagnosisId: diagnosisId,
            note: this.note,
            x1: x1,
            x2: x2,
            channel: channel,
            model_name: model_name,
            ai_answer: ai_answer,
            localStorageAnomalySequence: localStorageAnomalySequence
          });
        } else {
          this.editNote({
            id: localStorage.getItem("note_id"),
            x1: x1,
            x2: x2,
            target: this.target,
            channel: channel,
            note: this.note,
            mode: noteMode
          });
          edit_pin(localStorage.getItem("note_id"), this.note);
        }
      }
    },
    ...mapActions("notes", ["addNote", "editNote"]),
    ...mapActions(["evaluatePrediction"])
  }
};
</script>

<style></style>
