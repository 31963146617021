var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wrapper" }, [
    _c(
      "form",
      {
        staticClass: "form-signin",
        attrs: {
          action: "#",
          method: "get",
          enctype: "multipart/form-data",
          name: "signupForm",
          id: "signupForm"
        },
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.register($event)
          }
        }
      },
      [
        _c("h2", { staticClass: "form-signin-heading" }, [
          _vm._v("Generate Token")
        ]),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.api_user_username,
              expression: "api_user_username"
            }
          ],
          staticClass: "form-control",
          attrs: {
            type: "text",
            placeholder: "API User Username",
            required: "",
            autofocus: "",
            onkeyup: "this.value=this.value.replace(/\\s+/g,'')"
          },
          domProps: { value: _vm.api_user_username },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.api_user_username = $event.target.value
            }
          }
        }),
        _c("br"),
        _c(
          "button",
          {
            staticClass: "btn btn-lg btn-primary btn-block",
            attrs: { type: "submit" }
          },
          [_vm._v("Generate")]
        ),
        _c("br"),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.token,
              expression: "token"
            }
          ],
          staticClass: "form-control",
          attrs: {
            type: "text",
            name: "token",
            placeholder: "Token will appear here"
          },
          domProps: { value: _vm.token },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.token = $event.target.value
            }
          }
        })
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }