var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wrapper" }, [
    _c(
      "form",
      {
        staticClass: "form-signin",
        attrs: {
          action: "#",
          method: "get",
          enctype: "multipart/form-data",
          name: "signupForm",
          id: "signupForm"
        },
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.register($event)
          }
        }
      },
      [
        _c("h2", { staticClass: "form-signin-heading" }, [_vm._v("Register")]),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.username,
              expression: "username"
            }
          ],
          staticClass: "form-control",
          attrs: {
            type: "text",
            name: "username",
            placeholder: "Username",
            required: "",
            autofocus: "",
            onkeyup: "this.value=this.value.replace(/\\s+/g,'')"
          },
          domProps: { value: _vm.username },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.username = $event.target.value
            }
          }
        }),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.password,
              expression: "password"
            }
          ],
          staticClass: "form-control",
          attrs: {
            type: "password",
            name: "password",
            placeholder: "Password",
            required: ""
          },
          domProps: { value: _vm.password },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.password = $event.target.value
            }
          }
        }),
        _c("label", { attrs: { for: "user-role" } }, [_vm._v("Role")]),
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.role,
                expression: "role"
              }
            ],
            staticClass: "form-control",
            attrs: { id: "role" },
            on: {
              change: function($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function(o) {
                    return o.selected
                  })
                  .map(function(o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.role = $event.target.multiple
                  ? $$selectedVal
                  : $$selectedVal[0]
              }
            }
          },
          [
            _c("option", { attrs: { value: "hospital" } }, [
              _vm._v("Hospital")
            ]),
            _c("option", { attrs: { value: "api", selected: "selected" } }, [
              _vm._v("API User")
            ]),
            _c("option", { attrs: { value: "admin" } }, [_vm._v("Admin")])
          ]
        ),
        _c("label", { attrs: { for: "hospital-access" } }, [
          _vm._v("Hospital Access")
        ]),
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.hospitalAccess,
                expression: "hospitalAccess"
              }
            ],
            staticClass: "form-control",
            attrs: { id: "hospital" },
            on: {
              change: function($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function(o) {
                    return o.selected
                  })
                  .map(function(o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.hospitalAccess = $event.target.multiple
                  ? $$selectedVal
                  : $$selectedVal[0]
              }
            }
          },
          [_c("option", { attrs: { value: "yuan" } }, [_vm._v("yuan")])]
        ),
        _c(
          "button",
          {
            staticClass: "btn btn-lg btn-primary btn-block",
            attrs: { type: "submit" }
          },
          [_vm._v("Register")]
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }