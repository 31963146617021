export default {
  token: null,
  username: "",
  // TODO remove localStorage call in d3_display.js
  role: localStorage.getItem("role") || "",
  diagnoses: null,
  rows: [],
  columns: [
    {
      label: "Patient ID",
      field: "patient_id"
    },
    {
      label: "Time",
      field: "datetime"
    },
    {
      label: "Measure Type",
      field: "measure_type"
    },
    {
      label: "Who measures",
      field: "measure_person"
    },
    {
      label: "Tags",
      field: "tagged"
    },
    {
      label: "",
      field: "delete"
    }
  ],
  numberOfElements: 0,
  diagnosesUpdateTime: null,
  note_mode: "",
  anomalyMode: false,
  anomalySequence: [],
  evaluationTags: [],
  anomalyModels: []
};
