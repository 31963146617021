import { render, staticRenderFns } from "./ECGSignals.vue?vue&type=template&id=26622f9c&"
import script from "./ECGSignals.vue?vue&type=script&lang=js&"
export * from "./ECGSignals.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/amonbazongo/inCare/incare_dashboard/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('26622f9c')) {
      api.createRecord('26622f9c', component.options)
    } else {
      api.reload('26622f9c', component.options)
    }
    module.hot.accept("./ECGSignals.vue?vue&type=template&id=26622f9c&", function () {
      api.rerender('26622f9c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/ECGSignals.vue"
export default component.exports