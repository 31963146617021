import Vue from "vue";
import Router from "vue-router";
import Home from "./views/Home.vue";
import Login from "./views/Login.vue";
import DiagnosisList from "./views/DiagnosisList.vue";
import DiagnosisDetails from "./views/DiagnosisDetails.vue";
import Register from "./views/Register.vue";
import GenerateToken from "./views/GenerateToken.vue";
import NProgress from "nprogress";
import store from "./store/store.js";

Vue.use(Router);

const routes = [
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: {
      requiresVisitor: true
    }
  },
  {
    path: "/register",
    name: "register",
    component: Register,
    meta: {
      requiresAuth: true,
      is_admin: true
    }
  },
  {
    path: "/generate_token",
    name: "generate_token",
    component: GenerateToken,
    meta: {
      requiresAuth: true,
      is_admin: true
    }
  },
  {
    path: "/diagnosis",
    name: "diagnosis",
    component: DiagnosisList,
    meta: {
      requiresAuth: true,
      is_regular: false
    },
    beforeEnter(routeTo, routeFrom, next) {
      store.dispatch("deletePinLocalStorageData").then(() => {
        next();
      });
    }
  },
  {
    path: "/diagnosis/:diagnosis_id",
    name: "diagnosis_details",
    component: DiagnosisDetails,
    meta: {
      requiresAuth: true
    },
    beforeEnter(routeTo, routeFrom, next) {
      store.dispatch("deleteAnomalyData").then(() => {
        next();
      });
    }
  },
  {
    path: "/",
    name: "home",
    component: Home
  }
];

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  NProgress.configure({ showSpinner: false });
  NProgress.start();
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.getters.loggedIn) {
      next({
        name: "login"
      });
    } else {
      next();
    }
  }
  if (to.matched.some(record => record.meta.requiresVisitor)) {
    if (store.getters.loggedIn) {
      next({
        name: "diagnosis"
      });
    } else {
      next();
    }
  }

  if (to.meta.is_admin) {
    if (store.state.main.role !== "admin") {
      next({
        name: "login"
      });
    } else {
      next();
    }
  } else {
    next();
  }

  if (!to.meta.is_regular) {
    if (store.state.main.role === "regular") {
      next({
        name: "home"
      });
    } else {
      next();
    }
  }

  next();
});

router.afterEach(() => {
  NProgress.done();
});

export default router;
