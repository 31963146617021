import * as apiUrl from "../../api_url.global";
import * as Util from "../../util/DiagnosisEnum.js";
import axios from "axios";

export default {
  setUpRows({ commit }, data) {
    commit("SAVE_ROWS", data.rows);
    commit("SAVE_ROWS_NUMBER", data.numberOfElements);
    commit("SAVE_UPDATE_TIME", data.diagnosesUpdateTime);
  },
  handleAnomalyState({ commit, state }, data) {
    if (state.anomalySequence.length > 0) {
      showAnomaly(state.anomalySequence, commit, false);
      showEvaluationTags(state.evaluationTags);
    } else {
      commit("SAVE_ANOMALY_MODE");
      //console.log(data.model_name);
      const getAnomaly = axios.get(
        apiUrl.url +
          "/medical/v1/anomaly/" +
          data.model_name +
          "/" +
          data.diagnosisId
      );
      const getEvaluation = axios.get(
        apiUrl.url + "/medical/v1/evaluation/" + data.diagnosisId
      );
      axios
        .all([getAnomaly, getEvaluation])
        .then(
          axios.spread((...responses) => {
            const resAnomaly = responses[0];
            const resEvaluation = responses[1];

            if (resAnomaly.data.data) {
              const out_a1 = resAnomaly.data.data;
              showModelAnomalies(out_a1, commit, true, data.model_name);
            } else {
              commit("DELETE_ANOMALY_MODE");
              alert("Annotation not available");
            }
            // Check evaluation
            if (resEvaluation.data.code === 0) {
              const evaluationTags = resEvaluation.data.data;
              if (evaluationTags.length !== 0) {
                commit("SAVE_EVALUATION", evaluationTags);
                showEvaluationTags(evaluationTags);
              }
            }
          })
        )
        .catch(err => {
          console.log(err);
          commit("DELETE_ANOMALY_MODE");
          alert("Machine Learning not available");
        });
    }
  },
  changeMode({ commit }) {
    commit("DELETE_ANOMALY_MODE");
    commit("DELETE_ANOMALY_SEQUENCE");
  },
  toggleAnomaly({ commit, state }) {
    if (state.anomalyMode) {
      commit("DELETE_ANOMALY_MODE");
      draw_pins();
    }
  },
  deleteAnomalyData({ commit }) {
    commit("DELETE_ANOMALY_MODE");
    commit("DELETE_ANOMALY_SEQUENCE");
    commit("DELETE_EVALUATION");
  },
  deleteAnomalyMode({ commit }) {
    commit("DELETE_ANOMALY_MODE");
  },
  deletePinLocalStorageData() {
    // TODO refactor localStorage call with d3 removal
    localStorage.removeItem("note_id");
    localStorage.removeItem("note_x1");
    localStorage.removeItem("note_x2");
    localStorage.removeItem("note_diagnosis_id");
    localStorage.removeItem("areaColor");
    localStorage.removeItem("evaluationColor");
    localStorage.removeItem("note_mode");
    localStorage.removeItem("note_channel");
  },
  requestDiagnoses({ commit, dispatch, state }) {
    const config = {
      url: apiUrl.url + "/medical/v1/diagnoses/dashboard",
      headers: { "Content-Type": "application/json" },
      method: "post",
      data: {
        medical_id: "01",
        measure_person: String(state.username),
        role: String(state.role),
        start_date: state.diagnosesUpdateTime
      },
      transformResponse: [].concat(
        axios.defaults.transformResponse,
        data => data.data
      )
    };

    return axios(config)
      .then(res => {
        const notes = res.data.map(diagnosis => {
          return {
            diagnosis_id: diagnosis.diagnosis_id,
            note: diagnosis.notes
          };
        });
        commit("INSERT_DIAGNOSIS", res.data);
        dispatch("notes/addNotes", notes, { root: true });
        return Promise.resolve(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  },
  destroyToken({ commit, dispatch, getters }) {
    return new Promise((resolve, reject) => {
      try {
        if (getters.loggedIn) {
          commit("DELETE_TOKEN");
          commit("DELETE_ROLE");
          commit("DELETE_USERNAME");
          commit("DELETE_DIAGNOSIS");
          dispatch("notes/deleteNotes", { root: true });
          commit("DELETE_ROWS");
          commit("DELETE_ROWS_NUMBER");
          commit("DELETE_UPDATE_TIME");
          localStorage.removeItem("role");
        }
        resolve(true);
      } catch (error) {
        //TODO remove this line, if vuex storage is changed or split
        localStorage.removeItem("vuex");
        resolve(true);
      }
    });
  },
  retrieveToken({ commit, dispatch, state }, credentials) {
    return new Promise((resolve, reject) => {
      axios.defaults.headers.common.Authorization = "Bearer " + state.token;

      const loginConfig = {
        url: apiUrl.url + "/medical/v1/login",
        headers: { platform: "web" },
        method: "post",
        data: {
          identifier: credentials.username,
          password: credentials.password
        }
      };
      axios(loginConfig)
        .then(res => {
          const token = res.data.access_token;
          const role = res.data.role;
          commit("SAVE_TOKEN", token);
          commit("SAVE_USERNAME", credentials.username);
          commit("SAVE_ROLE", role);
          // TODO refactor localStorage call with d3 removal
          localStorage.setItem("role", role);
          return Promise.resolve(res.data);
        })
        .then(res => {
          const config = {
            url: apiUrl.url + "/medical/v1/diagnoses/dashboard",
            headers: { "Content-Type": "application/json" },
            method: "post",
            data: {
              medical_id: "01",
              measure_person: String(credentials.username),
              role: String(res.role)
            }
          };
          return axios(config);
        })
        .then(res => {
          const notes = res.data.data.map(diagnosis => {
            return {
              diagnosis_id: diagnosis.diagnosis_id,
              note: diagnosis.notes
            };
          });
          commit("SAVE_DIAGNOSIS", res.data.data);
          dispatch("notes/saveNotes", notes, { root: true });
          resolve(res);
        })
        // TODO error handling for every single request
        .catch(err => {
          console.log(err);
          reject(err);
        });
    });
  },
  evaluatePrediction({ state }, data) {
    // modify AI prediction with evaluation
    var newDiagnosisNumber = Util.getAnomalyPredictionNumber(
      data.note.toString()
    );
    // calculate seconds to be modified
    var startSec = Math.floor(data.x1 / 250);
    var endSec = Math.ceil(data.x2 / 250);
    // swap coordinates in case of backwards marking
    if (startSec > endSec) {
      [startSec, endSec] = [endSec, startSec];
    }
    var entriesToChange = endSec - startSec;
    var newSequenceSection = Array.from(
      Array(entriesToChange),
      x => newDiagnosisNumber
    );
    // copy original sequence and modify with calculated section
    var modifiedSequence = state.anomalySequence.slice();
    // TODO REMOVE workaround for non-existant channel predictions
    if (data.channel !== "1") {
      modifiedSequence = Array.from(Array(30), () => -1);
    }
    modifiedSequence.splice(startSec, entriesToChange, ...newSequenceSection);
    let evaluation;
    if (data.model_name !== "model_a") {
      evaluation = {
        diagnosis_id: data.diagnosisId.toString(),
        evaluation: data.note.toString() + ":" + data.ai_answer.toString(),
        model_name: data.model_name.toString(),
        evaluator: state.username,
        score: "",
        ai_sequence: [data.localStorageAnomalySequence],
        evaluator_sequence: [],
        channel: data.channel.toString(),
        x1: data.x1.toString(),
        x2: data.x2.toString()
      };
    } else {
      evaluation = {
        diagnosis_id: data.diagnosisId.toString(),
        evaluation: data.note.toString(),
        model_name: data.model_name.toString(),
        evaluator: state.username,
        score: "",
        ai_sequence: state.anomalySequence,
        evaluator_sequence: modifiedSequence,
        channel: data.channel.toString(),
        x1: data.x1.toString(),
        x2: data.x2.toString()
      };
    }

    const config = {
      url: apiUrl.url + "/medical/v1/evaluation",
      headers: {
        Authorization: "Bearer " + state.token,
        "Content-Type": "application/json"
      },
      method: "post",
      data: evaluation
    };
    return axios(config)
      .then(() => {
        alert("Thank you for your help!");
        add_evaluation(data);
      })
      .catch(err => console.log(err));
  },
  deleteDiagnosis(context, data) {
    const config = {
      url: apiUrl.url + "/medical/v1/diagnoses/" + data.diagnosis_id,
      headers: {
        Authorization: "Bearer " + context.state.token,
        "Content-Type": "application/json"
      },
      method: "delete"
    };

    return new Promise((resolve, reject) => {
      axios(config)
        .then(() => {
          context.commit("DELETE_DIAGNOSIS_BY_ID", data.diagnosis_id);
          resolve(data.diagnosis_id);
        })
        .catch(err => console.log(err));
    });
  },
  registerUser({ state }, data) {
    const config = {
      url: apiUrl.url + "/medical/v1/register",
      headers: {
        Authorization: "Bearer " + state.token,
        "Content-Type": "application/json"
      },
      method: "post",
      data: {
        username: data.username,
        password: data.password,
        role: data.role,
        hospitalAccess: data.hospitalAccess,
        phone: ""
      }
    };
    axios(config).catch(err => console.log(err));
  },
  generateToken({ state }, data) {
    const config = {
      url: apiUrl.url + "/medical/v1/generate_token/" + data.api_user_username,
      headers: {
        Authorization: "Bearer " + state.token,
        "Content-Type": "application/json"
      },
      method: "get"
    };

    return new Promise((resolve, reject) => {
      axios(config)
        .then(resp => {
          resolve(resp.data);
        })
        .catch(err => console.log(err));
    });
  },
  getAnomalyModels({ commit }) {
    axios.get(apiUrl.url + "/medical/v1/models/name").then(res => {
      const models = [];
      models.push(res.data.default);
      for (let challenge in res.data.challengers) {
        models.push(res.data.challengers[challenge]);
      }
      commit("SAVE_MODELS", models);
    });
  }
};

function showAnomaly(sequence, commit, isFresh) {
  var sum = 0;
  var n = sequence.length;
  for (var i = 0; i < n; i++) {
    sum += sequence[i];
  }
  // Check if anomaly exists
  if (sum == 0) {
    alert("ECG is normal!");
    commit("DELETE_ANOMALY_MODE");
  } else {
    isFresh ? alert("Anomaly is detected!") : isFresh;

    var s = 0;
    var e = 250;
    var s_e = [];
    while (e <= 7500) {
      s_e.push([s, e]);
      s = s + 250;
      e = e + 250;
    }
    var start_end = [[], s_e, [], [], [], []];

    draw_anomalies([[], sequence, [], [], [], []], "model_a", start_end);
    commit("SAVE_ANOMALY_MODE");
  }
}
function showEvaluationTags(evaluation) {
  draw_evaluation(evaluation);
}

function showModelAnomalies(sequence, commit, isFresh, model_name) {
  var result_cut = sequence.result;
  var start_end_cut = sequence.start_end_peak;
  if (
    (result_cut[0] === []) &
    (result_cut[1] === []) &
    (result_cut[2] === []) &
    (result_cut[3] === []) &
    (result_cut[4] === []) &
    (result_cut[5] === [])
  ) {
    alert("ECG is normal!");
    commit("DELETE_ANOMALY_MODE");
  } else {
    isFresh ? alert("Anomaly is detected!") : isFresh;

    draw_anomalies(result_cut, model_name, start_end_cut);
    commit("SAVE_ANOMALY_MODE");
  }
}
